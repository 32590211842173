

.whiteBoard{
    background: white;
    border: 1px solid black;
  }

.blackBoard{
    background: #505160;
    border: 1px solid black;
}

.whitePiece{
  background: lavender;
  border: 2px solid black;
}

.blackPiece{
  background: black;
  border: 2px solid lavender;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row;
  justify-content: space-around;

}
.backgroundZ{
  z-index: -1;
}

.circle {
  width: 65%;
  height: 65%;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}

.circle:hover {
  border: 2px solid #fcba03;
}

.flex-item {
  position: relative;
  width: 100%;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  margin: 0px;
  border: 1px solid black;
}

.flex-item:hover{
  border: 1px solid #fcba03;
}

.highlighted{
  background-color: #fcba03;
  animation: 1s infinite alternate ease-out breathing;
}

@keyframes breathing{
  from
  {
      background-color: hsl(44, 98%, 50%);
      opacity: 1;
  }
  to
  {
    background-color: hsl(44, 98%, 50%);
    opacity: 0.55;
  }
}

.flex-item:after {
  content: ' ';
  padding-top: 100%;
  display: table;
}

.noSelect{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}