.center{
    margin: auto;
}

.fullScreen{
    height:100vh;
    overflow: hidden;
}

.white-background{
    background-color: white;
}

.btnLnk{
    background: none!important;
    border: none;
    padding: 0!important;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}