.fullScreen {
    height: 100vh;
    width: 100vw;
}

.darkBtn{
    background-color: #505160;
    color: lavender;
    border-color: lavender;
}

.center{
    margin: auto;
}

.fullScreen{
    height:100vh;
    overflow: hidden;
}

.white-background{
    background-color: white;
}

.reversiColors{
    background-color: #505160;
    background-image: -webkit-linear-gradient(-30deg, #505160 50%, lavender 50%);
}

.reversiHeader{
    background: -webkit-linear-gradient(-20deg, lavender  50%, #505160 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.centerParent{
    align-items: center;
    margin: auto;
}

.big {
    width: 100%;
    font-size: 500%;
}